<template>
  <div class="client-orders-item-conclusion">
    <div class="block card"
         v-if="$currentUser.isAdmin || order.type === 'archetype' && stylistConclusion ||
          order.type === 'colorType' && !canMakeConclusion && !canChangeConclusion">
      <clients-orders-item-info-block class="conclusion"
                                      :label="stylistConclusionLabel"
                                      :value="stylistConclusion ? stylistConclusion.title : null"
                                      :label-left="false">
      </clients-orders-item-info-block>

      <clients-orders-item-info-block class="conclusion"
                                      v-if="order.type === 'colorType' || $currentUser.isAdmin"
                                      :label="mainStylistConclusionLabel"
                                      :value="mainStylistConclusion ? mainStylistConclusion.title : null"
                                      :label-left="false">
      </clients-orders-item-info-block>
    </div>

    <div class="block card"
         v-if="!$currentUser.isAdmin && order.type === 'archetype' || canMakeConclusion || canChangeConclusion">
      <template v-if="order.type === 'colorType'">
        <clients-orders-item-info-block class="conclusion"
                                        v-if="!canMakeConclusion"
                                        :label="stylistConclusionLabel"
                                        :value="stylistConclusion ? stylistConclusion.title : null"
                                        :label-left="false">
        </clients-orders-item-info-block>

        <base-select-tag class="conclusion"
                         v-if="canMakeConclusion || canChangeConclusion && isEditing"
                         v-model="currentConclusion"
                         :keys="{ value: order.type === 'colorType' ? 'color_type_id' : 'archetype_id', label: 'title' }"
                         :options="options">
          <div class="conclusion-label base-field-label"
               v-if="canMakeConclusion"
               slot="label">
            {{ $t(`clientsOrders.item.conclusionLabel.${order.type}.you`) }}:
          </div>
        </base-select-tag>
      </template>

      <clients-orders-item-conclusion-archetype-table class="conclusion"
                                                      v-else
                                                      v-model="currentConclusion"
                                                      :gender="order.client_gender"
                                                      :editable="canMakeConclusion || canChangeConclusion && isEditing"/>

      <div class="buttons"
           v-if="canMakeConclusion || canChangeConclusion">
        <base-button class="full-width"
                     v-if="canMakeConclusion"
                     :disabled="!currentConclusion"
                     @click="setConclusion">
          {{ $t('clientsOrders.item.submitButton') }}
        </base-button>

        <template v-if="canChangeConclusion">
          <base-button class="check-button"
                       @click="isEditing ? setConclusion() : approveConclusion()">
            {{ isEditing ? $t('clientsOrders.item.submitChangesButton') : $t('clientsOrders.item.approveButton') }}
          </base-button>

          <base-button class="check-button"
                       transparent
                       @click="isEditing ? cancelChanges() : isEditing = true">
            {{ isEditing ? $t('clientsOrders.item.cancelChangesButton') : $t('clientsOrders.item.changeButton') }}
          </base-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ClientsOrdersItemInfoBlock from '../ClientsOrdersItemInfoBlock.vue'
  import ordersService from '@/services/queries/ordersQueries'
  import ClientsOrdersItemConclusionArchetypeTable from './ClientsOrdersItemConclusionArchetypeTable.vue'

  export default {
    props: {
      order: {
        required: true
      },
    },

    components: {
      ClientsOrdersItemInfoBlock,
      ClientsOrdersItemConclusionArchetypeTable
    },

    data() {
      return {
        isEditing: false,

        canMakeConclusion: false,
        canChangeConclusion: false,

        stylistConclusion: null,
        mainStylistConclusion: null,
        currentConclusion: null,

        stylistConclusionLabel: this.$t(`clientsOrders.item.conclusionLabel.${this.order.type}.stylist`),
        mainStylistConclusionLabel: this.$t(`clientsOrders.item.conclusionLabel.${this.order.type}.mainStylist`)
      }
    },

    methods: {
      setConclusion() {
        let submit = this.order.type === 'archetype' ? this.setArchetype() : this.setColorType()

        submit.then(() => {
          this.$emit('conclusion-update')
        })
      },

      setArchetype() {
        return ordersService.setArchetype(this.order.id, this.currentConclusion.archetype_id)
      },

      setColorType() {
        return ordersService.setColorType(this.order.id, this.currentConclusion.color_type_id)
      },

      cancelChanges() {
        this.isEditing = false

        if (this.order.type !== 'archetype') {
          return
        }

        this.currentConclusion = this.stylistConclusion
      },

      approveConclusion() {
        ordersService.approveConclusion(this.order.id).then(() => {
          this.$emit('conclusion-update')
        })
      },

      getConclusionById(conclusionId) {
        return this.order.type == 'colorType' ? this.getColorTypeById(conclusionId) : this.getArchetypeById(conclusionId)
      },

      setUserPermissions() {
        this.canMakeConclusion = false
        this.canChangeConclusion = false

        if (!this.order.stylist) {
          return
        }

        let isCurrentUserInCharge = this.order.stylist.id === this.$currentUser.id

        if (isCurrentUserInCharge) {
          this.canMakeConclusion = this.order.status === 'PROCESSING'
        } else {
          this.canChangeConclusion = this.order.status === 'CHECK_REQUIRED' && this.$currentUser.isMainStylist
        }
      },

      setConclusionLabels() {
        let labelStart = `clientsOrders.item.conclusionLabel.${this.order.type}`
        let isUserInCharge = this.order.stylist && this.$currentUser.id === this.order.stylist.id

        this.stylistConclusionLabel = this.$t(`${labelStart}.${isUserInCharge && !this.$currentUser.isMainStylist ? 'you' : 'stylist'}`)
        this.mainStylistConclusionLabel = this.$t(`${labelStart}.${isUserInCharge ? 'you' : 'mainStylist'}`)
      }
    },

    computed: {
      options() {
        if (!this.order) {
          return []
        }

        return this.order.type === 'colorType' ?
          this.$store.state.handbooks.colorTypes :
          this.$store.state.handbooks.archetypes
      },

      ...mapGetters('handbooks', [
        'getArchetypeById'
      ]),

      ...mapGetters('handbooks', [
        'getColorTypeById'
      ]),
    },

    watch: {
      order: {
        handler(order) {
          this.isEditing = false

          this.stylistConclusion = this.getConclusionById(this.order.stylistConclusionId)
          this.mainStylistConclusion = this.getConclusionById(this.order.mainStylistConclusionId)
          this.currentConclusion = this.mainStylistConclusion || this.stylistConclusion

          this.setUserPermissions()
          this.setConclusionLabels()
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .client-orders-item-conclusion {
    display: flex;
    flex-direction: column;

    .block {
      display: flex;
      flex-direction: column;

      &:last-child {
        flex-grow: 1;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .conclusion {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .conclusion-label {
        margin-bottom: 16px;
        font-size: 16px;
      }
    }

    .buttons {
      display: flex;
      margin-top: auto;

      .check-button {
        flex-grow: 1;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
</style>
