import { render, staticRenderFns } from "./ClientsOrdersItemPhotoGallery.vue?vue&type=template&id=1a30b077&scoped=true&"
import script from "./ClientsOrdersItemPhotoGallery.vue?vue&type=script&lang=js&"
export * from "./ClientsOrdersItemPhotoGallery.vue?vue&type=script&lang=js&"
import style0 from "./ClientsOrdersItemPhotoGallery.vue?vue&type=style&index=0&id=1a30b077&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a30b077",
  null
  
)

export default component.exports