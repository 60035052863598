<template>
  <div class="clients-orders-item-photo-gallery block card">
    <image-gallery v-if="order.images.length"
                   :images="order.images"
                   :editable="false"
                   preview-size="origin"
                   image-width="calc(100% / 3)">
      <div v-if="['PROCESSING', 'FROZEN'].includes(order.status)"
           slot="tool-right"
           slot-scope="props">
        <base-button-icon v-if="!$currentUser.isAdmin && !props.image.is_declined"
                          icon="cross"
                          buttonClass="small"
                          iconWidth="15px"
                          iconHeight="15px"
                          v-tippy="{ placement: 'bottom' }"
                          :title="$t('clientsOrders.item.photoGallery.declinePhotoButton.active')"
                          @click="$emit('open-decline-photo-modal', props.image.image_id)">
        </base-button-icon>

        <base-button-icon class="disabled"
                          v-else-if="props.image.is_declined"
                          icon="cross"
                          buttonClass="small"
                          iconWidth="15px"
                          iconHeight="15px"
                          v-tippy="{ placement: 'bottom' }"
                          :title="$t('clientsOrders.item.photoGallery.declinePhotoButton.disabled')">
        </base-button-icon>
      </div>
    </image-gallery>

    <span class="no-images-placeholder"
          v-if="!order.images.length">
      {{ $t('clientsOrders.item.photoGallery.noImagesPlaceholder') }}
    </span>
  </div>
</template>

<script>
  import ImageGallery from '@/components/Shared/ImageGallery/ImageGallery.vue'

  export default {
    props: {
      order: {
        required: true,
        type: Object
      }
    },

    components: {
      ImageGallery
    },
  }
</script>

<style lang="scss" scoped>
  .clients-orders-item-photo-gallery {
    .no-images-placeholder {
      font-size: 16px;
      color: #1C1C1C;
    }
  }
</style>
