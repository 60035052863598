import ordersService from '@/services/queries/ordersQueries'
import ClientsOrdersItemNavigationPanel from './ClientsOrdersItemNavigationPanel.vue'
import ClientsOrdersItemInfoBlock from './ClientsOrdersItemInfoBlock.vue'
import ClientsOrdersItemConclusion from './ClientsOrdersItemConclusion/ClientsOrdersItemConclusion.vue'
import ClientsOrdersItemPhotoGallery from './ClientsOrdersItemPhotoGallery.vue'
import ClientsOrdersItemDeclinePhotoModal from './ClientsOrdersItemDeclinePhotoModal.vue'

export default {
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },

  components: {
    ClientsOrdersItemNavigationPanel,
    ClientsOrdersItemInfoBlock,
    ClientsOrdersItemConclusion,
    ClientsOrdersItemPhotoGallery,
    ClientsOrdersItemDeclinePhotoModal
  },

  data() {
    return {
      order: null,
      orderStatus: null,
      mainImageLink: null,
      clientGender: null,
      clientBodyShape: null,
      isLeftPanelActive: false,
      isRightPanelActive: false,
      previousOrderOffset: null,
      nextOrderOffset: null,
      declinePhotoModal: {
        show: false,
        imageId: null
      },
      orderListRoute: {
        name: 'clientsOrders',
        query: _.omit(this.$route.query, ['order_offset', 'total_count'])
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t(`clientsOrders.item.pageTitle.${this.order ? this.order.type : 'colorType'}`)
    }
  },

  methods: {
    getOrder() {
      ordersService.find(this.id).then(order => {
        this.order = order

        this.orderStatus = this.$t('orderStatuses').find(orderStatus => orderStatus.value === this.order.status)

        if (this.order.type === 'archetype') {
          this.setClientFeatures()
        }

        this.mainImageLink = this.order.images[0].slices_info.find(sliceInfo => sliceInfo.title === 'origin').url

        this.setSiblingOrdersIndexes()

        this.isLeftPanelActive = false
        this.isRightPanelActive = false

        this.$nextTick(() => {
          this.$refs.rootElement.focus() // for keyboard shortcuts for navigation panels
        })
      })
    },

    setClientFeatures() {
      this.clientGender = this.$t('genders').find(gender => gender.value === this.order.clientGender.toLowerCase()).label
      this.clientBodyShape = this.getFigureTypeById(this.order.clientBodyShapeId).title
    },

    setSiblingOrdersIndexes() {
      let ordersRequestInfo = { ...this.$route.query }

      if (!Object.keys(ordersRequestInfo).length) {
        this.previousOrderOffset = null
        this.nextOrderOffset = null

        return
      }

      let currentOrderOffset = +ordersRequestInfo.order_offset
      let totalNumberOfOrders = +ordersRequestInfo.total_count

      this.previousOrderOffset = currentOrderOffset === 0 ? null : currentOrderOffset - 1
      this.nextOrderOffset = currentOrderOffset === totalNumberOfOrders - 1 ? null : currentOrderOffset + 1
    },

    openPreviousOrder() {
      this.openOrder(this.previousOrderOffset)
    },

    openNextOrder() {
      this.openOrder(this.nextOrderOffset)
    },

    openOrder(orderOffset) {
      if (orderOffset === null) {
        return
      }

      let currentOrderRouteQuery = { ...this.$route.query }
      let orderRequestQuery = _.omit({
        ...currentOrderRouteQuery,
        offset: orderOffset,
        limit: 1
      }, ['order_offset', 'total_count'])

      ordersService.get(orderRequestQuery).then(response => {
        this.$router.push({
          name: 'clientsOrders.item',
          params: { id: response.orders[0].id },
          query: { ...currentOrderRouteQuery, order_offset: orderOffset }
        })
      })
    },

    openDeclinePhotoModal(imageId) {
      this.declinePhotoModal.show = true
      this.declinePhotoModal.imageId = imageId
    },

    closeDeclinePhotoModal() {
      this.declinePhotoModal.show = false
      this.declinePhotoModal.imageId = null
    },

    onDeclinePhoto() {
      this.closeDeclinePhotoModal()

      this.getOrder()
    }
  },

  computed: {
    ...mapGetters('handbooks', [
      'getFigureTypeById'
    ])
  },

  watch: {
    id: {
      handler() {
        this.getOrder()
      },
      immediate: true
    }
  }
}
