<template>
  <base-modal-confirm :show="show"
                      :title="$t('clientsOrders.item.declinePhotoModal.title')"
                      :confirmButtonText="$t('clientsOrders.item.declinePhotoModal.declineButton')"
                      :inProgress="inProgress"
                      :serverError="serverError"
                      @confirm="declinePhoto"
                      @close="$emit('close')">
    {{ $t('clientsOrders.item.declinePhotoModal.text') }}
  </base-modal-confirm>
</template>

<script>
  import ordersService from '@/services/queries/ordersQueries'

  export default {
    props: {
      show: {
        required: true,
        type: Boolean,
        default: false
      },

      orderId: {
        required: true,
        type: [Number, String]
      },

      photoId: {
        required: true
      }
    },

    data() {
      return {
        inProgress: false,
        serverError: false
      }
    },

    methods: {
      declinePhoto() {
        this.inProgress = true
        this.serverError = false

        ordersService.declinePhoto(this.orderId, this.photoId).then(() => {
          this.inProgress = false

          this.$emit('decline')
        }).catch(() => {
          this.serverError = true
          this.inProgress = false
        })
      },
    }
  }
</script>