<template>
  <div class="clients-orders-item-info-block"
       :class="{ 'single-row': labelLeft }">
    <div class="label base-field-label">
      {{ label }}:
    </div>

    <div class="value text">
      <slot>{{ value || placeholder }}</slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        required: false,
        type: String
      },

      value: {
        required: false,
        type: [String, Number]
      },

      placeholder: {
        required: false,
        type: String
      },

      labelLeft: {
        required: false,
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clients-orders-item-info-block {
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .label {
      margin-bottom: 5px;
      font-size: inherit;
    }

    .value {
      color: #1C1C1C;
    }

    &.single-row {
      display: flex;
      align-items: center;

      .label {
        margin: 0 5px 0 0;
      }
    }
  }
</style>