<template>
  <div class="clients-orders-item-archetype-table">
   <table class="table">
      <thead class="table-header">
        <tr class="table-row">
          <th class="table-header-cell">
            {{ $t('clientsOrders.item.archetypeTable.archetype') }}
          </th>
          <th class="table-header-cell">
            {{ $t('clientsOrders.item.archetypeTable.height') }}
          </th>
          <th class="table-header-cell">
            {{ $t('clientsOrders.item.archetypeTable.bodyShape') }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-row table-row-hover table-row-rounded"
            :class="{ 'table-row-click': editable,
            'table-row-bordered': value && value.archetype_id === archetype.archetype_id }"
            v-for="archetype in filteredArchetypes"
            :key="archetype.archetype_id"
            @click="editable ? $emit('update', archetype) : null">
          <td class="table-cell table-cell-centered">
            {{ archetype.title }}
          </td>
          <td class="height-cell table-cell table-cell-centered">
            {{ archetype.height_min }} - {{ archetype.height_max }}
          </td>
          <td class="body-shape-cell table-cell table-cell-centered">
            <span class="body-shape-icon"
                  v-for="bodyShape in archetype.suitable_figure_types"
                  :key="bodyShape"
                  :title="getFigureTypeById(bodyShape).title"
                  :style="{'background-image': `url(${getFigureTypeById(bodyShape).images.small.url})`}"
                  v-tippy="{ theme: 'light' }">
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'value',
      event: 'update'
    },

    props: {
      value: {
        required: false
      },

      gender: {
        required: true,
        type: String,
        default: 'FEMALE'
      },

      editable: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    computed: {
      filteredArchetypes() {
        return this.$store.state.handbooks.archetypes.filter(archetype => archetype.gender === this.gender)
      },

      ...mapGetters('handbooks', [
        'getFigureTypeById'
      ])
    },
  }
</script>

<style lang="scss" scoped>
  .height-cell {
    min-width: 100px;
  }

  .body-shape-cell {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .body-shape-icon {
    width: 20px;
    height: 20px;
    margin: 0 0 5px;
    background: center no-repeat;
    background-size: contain;
  }
</style>
