<template>
  <div class="clients-orders-item-navigation-panel">
    <div class="inner"
         :class="{ 'active': active, 'left': type === 'left', 'right': type === 'right' }"
         v-if="!disabled"
         v-tippy="{ placement: type === 'left' ? 'right' : 'left', theme: 'light' }"
         :title="$t('clientsOrders.item.navigationPanel.previous')"
         @mousedown="$emit('mousedown', $event)"
         @mouseup="$emit('mouseup', $event)">
      <base-icon :icon="type === 'left' ? 'arrowLeft' : 'arrowRight'"
                 width="60px"
                 height="60px"
                 color="currentColor"/>
    </div>

    <div class="inner disabled"
         v-else>
    </div>
  </div>
</template>


<script>
  export default {
    props: {
      type: {
        required: true,
        type: String
      },

      active: {
        required: true,
        type: Boolean
      },

      disabled: {
        required: true,
        type: Boolean
      }
    },
  }
</script>

<style lang="scss" scoped>

  .clients-orders-item-navigation-panel {
    width: 10%;
    min-width: 80px;
    max-width: 150px;
  }

  .inner {
    position: fixed;
    bottom: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    min-width: inherit;
    max-width: inherit;
    color: rgba($primary-color, 0.5);
    cursor: pointer;
    transition: all .2s;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &:not(.disabled):hover {
      color: $primary-color;
      background-color: rgba($primary-color, 0.1);
    }

    &.active {
      color: darken($primary-color, 10%);
      background-color: rgba($primary-color, 0.2);
    }

    &.disabled {
      cursor: default;
    }
  }
</style>
